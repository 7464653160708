<template>
  <section class="Content-Page">
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 col-md-3">
                <h3 class="page-title">Stripe</h3>
              </div>
            </div>
          </div>
          <div class="table-responsive pb-2">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                   <th>Currency</th>
                   <th>Language</th>
                  <th>SandBox Mode</th>
                   <th>PK Text</th>
                    <th>SK Text </th>
                    <th>PK Live</th>
                    <th>SK Live</th>
                    <th>Return  URL</th>
                    <th>WebHook Client Secret</th>
                    <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="info">
                <tr >
                  <td>
                    {{info.STRIPE_CURRENCY}}
                  </td>
                  <td>
                    {{info.STRIPE_LANGUAGE}}
                  </td>
                  <td>
                      {{info.STRIPE_SANDBOX_MODE}}
                    </td>
                    <td>
                      {{info.STRIPE_PK_TEST}}
                    </td>
                     <td>
                      {{info.STRIPE_SK_TEST}}
                    </td>
                    <td>
                      {{info.STRIPE_PK_LIVE}}
                    </td>
                    <td>
                      {{info.STRIPE_SK_LIVE}}
                    </td>
                    <td>
                      {{info.STRIPE_RETURN_URL}}
                    </td>
                    <td>
                      {{info.STRIPE_WEBHOOK_CLIENT_SECRET}}
                    </td>
                    <td>
                    <router-link
                      class="badge badge-info"
                      :to="`/settings/edit-stripe`"
                      >Edit
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
           <div class="col-12 p-3 text-center" v-if="info == ''">
              No data found!
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
export default {
  name: "stripe-settings",
  components: {
    Loader,
  },
  data() {
    return {
      info: null,
      showLoader: false,
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      this.showLoader = true;
      const { data } = await setingsApi.stripe();
      this.info = data.data.data;
      this.showLoader = false;
    }
  },
};
</script>

<style scoped></style>
